<template>
  <page>
    <template #navigation>
      <div class="flex -mx-1">
        <action-icon class="mx-1" variant="primary" face="mdiPencilPlus" small @click="onAddUser" v-if="isAdmin" />
        <div class="flex-1 mx-1">
          <field compact :placeholder="$t('general.search')" small type="search" v-model="search" />
        </div>
      </div>
    </template>
    <div class="flex flex-row flex-wrap -mx-1">
      <div v-for="member in members" :key="member._id" class="w-full md:w-1/2 lg:w-1/3">
        <div class="
            m-2
            flex
            items-start
            justify-start
            bg-white
            border border-gray-200
            relative
          ">
          <div class="absolute top-1 right-1 flex flex-col">
            <chip class="block mt-px" v-if="member._id === user._id" variant="success" size="xs">{{ $t('users.itsYou')
              }}
            </chip>
            <chip class="block mt-px" v-if="member.hide === 'YES' || member.hide === true" variant="danger" size="xs">{{
              $t('users.hides.short') }}</chip>
          </div>
          <div class="
              w-24
              h-24
              m-4
              shrink-0
              rounded-full
              border border-gray-200
              bg-white
              z-10
              bg-center bg-no-repeat bg-cover
            " :style="{
              backgroundImage: `url(${getAvatarUrl(member)}`,
              flexShrink: 0
            }"></div>
          <div>
            <div class="py-4">
              <div class="text-left" v-for="detail in getDetails(member)" :key="detail.id">
                <p :class="detail.style" class="
                    w-full
                    overflow-hidden overflow-ellipsis
                    whitespace-nowrap
                  " :title="$t(detail.label)">
                  {{ detail.value }}
                </p>
              </div>
            </div>
            <div class="pb-4 flex flex-row justify-start align-start" v-if="isAdmin">
              <action-icon class="mx-1" variant="danger" @click="onDeleteUser(member)"
                :disabled="isBusy || member._id === user._id" face="mdiDeleteForeverOutline" />
              <action-icon class="mx-1" variant="primary" @click="onEditUser(member)" face="mdiPencil" />
              <action-icon v-if="isAdmin" :disabled="member._id === user._id" class="mx-1" variant="outline"
                @click="onImpersonateUser(member)" face="mdiAccountEdit" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="currentUser" @close="currentUser = null" :title="$t('actions.editName', currentUser)">
      <form @submit.prevent="onSaveUser">
        <field name="name" i18n="profile.name" v-model="currentUser.name" @changed="currentUser.clear('name')"
          :error="currentUser.i18n('name')" />
        <field name="email" i18n="profile.email" v-model="currentUser.email" type="email"
          @changed="currentUser.clear('email')" :error="currentUser.i18n('email')" />
        <field name="phone" i18n="profile.phone" v-model="currentUser.phone" type="tel"
          @changed="currentUser.clear('phone')" :error="currentUser.i18n('phone')" />
        <selector name="roles" i18n="profile.role" v-model="currentUser.role" :options="roles"
          :disabled="isBusy || isCurrentUser" />
        <selector name="hide" i18n="profile.hide" v-model="currentUser.hide" :options="hides" :disabled="isBusy" />
        <field :i18n="currentUser._id === '#add'
            ? 'profile.password'
            : 'profile.changePassword'
          " name="password" v-model="currentUser.password" autocomplete="new-password" type="password"
          @changed="currentUser.clear('password')" :error="currentUser.i18n('password')" />
        <div class="flex justify-end mt-4">
          <action variant="link" @click="currentUser = null" :disabled="isBusy">{{ $t('actions.cancel') }}</action>
          <action variant="primary" type="submit" :disabled="isBusy" :loading="isBusy">{{ $t('actions.save') }}</action>
        </div>
      </form>
    </modal>
    <modal v-if="impersonate" @close="hideImpersonating" :title="$t('impersonate.title', impersonate)">
      <p class="text-sm text-gray-700">
        {{ $t('impersonate.text', impersonate) }}
      </p>
      <form @submit.prevent="requestImpersonateUser">
        <field class="mt-4" i18n="impersonate.password" type="password" autocomplete="current_password"
          v-model="safetyPassword" :disabled="isImpersonating" />
        <div class="flex items-center justify-end">
          <action type="button" variant="link" @click="hideImpersonating" :disabled="isImpersonating">{{
            $t('actions.cancel') }}</action>
          <action type="submit" variant="primary" :disabled="isImpersonating || safetyPassword.length < 6"
            :loading="isImpersonating">{{ $t('impersonate.button', impersonate) }}</action>
        </div>
      </form>
    </modal>
    <modal v-if="confirmDelete" :title="$t('users.destroyTitle', confirmDelete)" @close="onCancelDelete">
      <p>{{ $t('users.destroyText', confirmDelete) }}</p>
      <div class="flex justify-between mt-4">
        <action variant="danger" :disabled="isDeleting" :loading="isDeleting" @click="deleteUser">{{
          $t('actions.yesSure')
          }}
        </action>
        <action variant="success" :disabled="isDeleting" @click="onCancelDelete">{{ $t('actions.no') }}</action>
      </div>
    </modal>
  </page>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Form, storage } from '@/services'

export default {
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) => state.users?.loading || state.users?.updating,
      users: (state) => state.users,
      user: (state) => state.user
    }),
    members() {
      const _search = this.search.trim().toLowerCase()
      if (!_search) {
        return this.users.items
      }
      return this.users.items.filter(
        (member) =>
          member.name.toLowerCase().includes(_search) ||
          member.email.toLowerCase().includes(_search) ||
          member.phone.includes(_search)
      )
    },
    isCurrentUser() {
      if (!this.currentUser) {
        return false
      }
      return this.currentUser._id === this.user?._id
    },
    isAdmin() {
      return this.user?.role === 'ADMIN'
    },
    roles() {
      return [
        { id: 'admin', value: 'ADMIN', label: this.$t('roles.ADMIN') },
        { id: 'agent', value: 'AGENT', label: this.$t('roles.AGENT') }
      ]
    },
    hides() {
      return [
        { id: 'yes', value: 'YES', label: this.$t('users.hides.yes') },
        { id: 'no', value: 'NO', label: this.$t('users.hides.no') }
      ]
    },
    noCache() {
      return `ts-${Math.floor(10000000 * Math.random())}`
    }
  },
  data() {
    return {
      search: '',
      currentUser: null,
      impersonate: null,
      safetyPassword: '',
      isImpersonating: false,
      confirmDelete: null,
      isDeleting: false
    }
  },
  methods: {
    ...mapActions(['users/create', 'users/update', 'users/destroy']),
    _err(field) {
      if (!this.currentUser) {
        return false
      }
      const e = this.currentUser.errors[field]
      return e ? this.$t(`validation.${e}`) : null
    },
    getAvatarUrl(member) {
      return `${process.env.VUE_APP_SERVER_URL}/media/avatar/${member._id}.png?_=${this.noCache}`
    },
    getDetails(member) {
      return [
        {
          id: 'name',
          style: 'font-bold',
          label: 'profile.name',
          value: member.name || '-'
        },
        {
          id: 'rile',
          style: 'text-xs text-gray-500',
          label: 'profile.name',
          value:
            member.role === 'ADMIN'
              ? this.$t('users.roles.admin')
              : this.$t('users.roles.agent')
        },
        {
          id: 'email',
          style: 'text-sm text-blue-600',
          label: 'profile.email',
          value: member.email || '-'
        },
        {
          id: 'phone',
          style: 'text-xs',
          label: 'profile.phone',
          value: member.phone || '-'
        }
      ]
    },

    onAddUser() {
      if (!this.isAdmin) {
        return false
      }
      this.currentUser = new Form(
        {
          _id: '#add',
          name: '',
          email: '',
          phone: '',
          role: 'AGENT',
          password: '',
          hide: 'YES'
        },
        {
          name: ['required'],
          email: ['required', 'email'],
          phone: ['required'],
          password: ['required']
        }
      )
    },
    onEditUser(member) {
      if (!this.isAdmin) {
        return false
      }
      this.currentUser = new Form(
        {
          ...JSON.parse(JSON.stringify(member)),
          password: '',
          hide: member.hide === true || member.hide === 'YES' ? 'YES' : 'NO'
        },
        {
          name: ['required'],
          email: ['required', 'email'],
          phone: ['required']
        }
      )
    },
    async onSaveUser() {
      if (!this.isAdmin || !this.currentUser) {
        this.currentUser = null
        return false
      }

      if (!this.currentUser.validate()) {
        return false
      }

      const { _id, name, email, phone, password, role, hide } =
        this.currentUser.data
      try {
        if (_id === '#add') {
          await this['users/create']({
            name,
            email,
            phone,
            password,
            role,
            hide: hide === true || hide === 'YES'
          })
        } else {
          await this['users/update']({
            _id,
            name,
            email,
            phone,
            password,
            role,
            hide: hide === true || hide === 'YES'
          })
        }
      } catch (err) {
        console.error('Error while saving user', err)
      } finally {
        this.currentUser = null
      }
    },
    onDeleteUser(member) {
      if (!this.isAdmin || this.user._id == member._id) {
        this.$toast('danger', 'noRights')
        return false
      }

      this.confirmDelete = member
    },
    onCancelDelete() {
      this.confirmDelete = null
      this.isDeleting = false
    },
    async deleteUser() {
      if (!this.confirmDelete) {
        this.isDeleting = false
        return
      }
      if (!this.isAdmin || this.user._id === this.confirmDelete._id) {
        this.confirmDelete = null
        this.isDeleting = false
        this.$toast('danger', 'noRights')
        return false
      }
      this.isDeleting = true

      try {
        await this['users/destroy']({ _id: this.confirmDelete._id })
      } catch (err) {
        console.error('Error while deleting user', err)
      } finally {
        this.isDeleting = false
        this.confirmDelete = null
      }
    },
    hideImpersonating() {
      if (this.isImpersonating) {
        return false
      }
      this.impersonate = null
      this.safetyPassword = ''
    },
    async onImpersonateUser(member) {
      if (!this.isAdmin) {
        return false
      }
      this.impersonate = member
      this.isImpersonating = false
      this.safetyPassword = ''
    },
    async requestImpersonateUser() {
      if (!this.isAdmin) {
        this.impersonate = false
        return
      }
      if (!this.impersonate) {
        return
      }
      this.isImpersonating = true
      try {
        const response = await this.$http.post('/auth/pose', {
          poseId: this.impersonate._id,
          password: this.safetyPassword
        })
        storage.token = response.data.token
        const profile = await this.$http.get('/auth/profile', { refresh: true })
        this.$store.dispatch('login', profile.data.data)
        this.$router.push('/')
      } catch (e) {
        console.error(`* Error while posing as ${this.impersonate}`, e)
        this.$toast('danger', 'errors.impersonating')
      } finally {
        this.isImpersonating = false
        this.impersonate = null
      }
    }
  }
}
</script>
